.appt-book-sections-container {
  .password-field__info {
    .password-field__rules {
      display: none;
    }
  }
  .appt-book-section-content {
    .appt-book-location-selector {
      .virtual__appointment-subheader {
        @include breakpoint($medium-up) {
          margin-top: 10px;
        }
      }
    }
    #appt-book-my-appointments-no-signin {
      .sign-in-container {
        .sign-in-form-container {
          a.login_forgot {
            display: block;
          }
        }
      }
    }
  }
}

.site-content {
  .appointment-cancellation {
    .cancel-content,
    .error-content {
      .appt-book-link {
        float: none;
        margin: 0;
        padding: 0.6em;
      }
    }
  }
}
