// This file is generated by `gulp icons`, do not modify

$icons: (
  account: '\EA01',
  bag: '\EA02',
  calendar: '\EA03',
  caret--cta-right: '\EA04',
  caret--down: '\EA05',
  caret--down2: '\EA06',
  caret--left: '\EA07',
  caret--right: '\EA08',
  caret--up: '\EA09',
  check: '\EA0A',
  checkbox--checked: '\EA0B',
  checkbox: '\EA0C',
  checkout: '\EA0D',
  circle-caret--left: '\EA0E',
  circle-caret--right: '\EA0F',
  close: '\EA10',
  email: '\EA11',
  facebook-v2: '\EA12',
  facebook: '\EA13',
  gift-icon: '\EA14',
  hamburger: '\EA15',
  head--outline: '\EA16',
  head--solid: '\EA17',
  heart--filled: '\EA18',
  heart--outline: '\EA19',
  instagram-v2: '\EA1A',
  instagram: '\EA1B',
  leaf: '\EA1C',
  line: '\EA1D',
  livechat: '\EA1E',
  location--filled: '\EA1F',
  location-search: '\EA20',
  location: '\EA21',
  logo--mobile: '\EA22',
  logo: '\EA23',
  map-marker: '\EA24',
  map-marker2: '\EA25',
  microphone: '\EA26',
  minus: '\EA27',
  pinterest--circle: '\EA28',
  pinterest: '\EA29',
  play: '\EA2A',
  plus: '\EA2B',
  pure-privilege: '\EA2C',
  radio--checked: '\EA2D',
  radio: '\EA2E',
  schedule: '\EA2F',
  search: '\EA30',
  search2: '\EA31',
  share: '\EA32',
  shipping: '\EA33',
  stars: '\EA34',
  twitter: '\EA35',
  video-play: '\EA36',
  weibo: '\EA37',
  youtube--play: '\EA38',
  youtube: '\EA39'
);
