.add-payment-page {
  .account-section {
    padding-top: 0 !important;
    margin-top: 0;
  }
  .cards-div {
    width: 100%;
    @include breakpoint($medium-only) {
      width: 70%;
    }
    .payment-section {
      width: 100%;
      height: 65px;
      label {
        width: 100%;
        float: left;
        @include breakpoint($medium-only) {
          margin-top: 20px;
          width: 23%;
        }
      }
      .input-field {
        height: 35px;
        width: 100%;
        border: 1px solid $light-gray;
        float: left;
        @include breakpoint($medium-only) {
          width: 50%;
          height: 50px;
          padding: 0 5px;
          margin: 0;
        }
      }
      &.expiry {
        .input-field {
          margin-right: 0px;
          width: 48%;
          &:first-of-type {
            margin-right: 4%;
          }
          @include breakpoint($medium-only) {
            width: 24%;
            &:first-of-type {
              margin-right: 14px;
            }
          }
        }
      }
    }
  }
}
