//Regional Color variables
$color-lighter-brown: #ff8c00;

//Adaptive placeholder Mixins
@mixin setdefault_height {
  height: 9.5em;
}

@mixin setdefault_inputmargin {
  margin-top: 9.5em;
}

@mixin setdefault_beforemargin {
  margin: 1em;
}

@mixin setdefault_inputpadding {
  padding-top: 1em;
}

h2 {
  font-weight: bold;
  margin: 15px 0;
}

h3 {
  font-weight: bold;
  margin: 15px 0;
}

section.panel {
  border-bottom: 1px solid $light-gray;
  padding-bottom: 10px;
  .sign-in-panel {
    .messages {
      [class*='error_offer_criteria_'] {
        display: none;
      }
    }
  }
}

.messages {
  background: transparent;
  border: none;
  margin: 10px 0;
  padding: 0;
}

#promo-panel {
  margin: 0;
  border: none;
}

#mobile-breadcrumb {
  display: none;
}

#viewcart-panel {
  margin: 0;
  border: none;
  .top-viewcart-buttons {
    display: none;
  }
  .viewcart-header {
    border-bottom: 1px solid $light-gray;
    padding: 0.4em 1em;
    float: $ldirection;
    width: 100%;
    font-weight: bold;
    h2 {
      float: $ldirection;
    }
    .item-count {
      float: $rdirection;
      margin: 20px 0;
    }
  }
  .single-message {
    color: $red;
  }
  .viewcart-panel__content {
    .cart-header,
    .size-label,
    .qty-label {
      display: none;
    }
    .signin-to-see-cart {
      a {
        text-decoration: underline;
      }
    }
    .cart-items.products {
      .cart-item {
        margin: 1em 0 2em 0;
        position: relative;
      }
      .thumb {
        width: 22%;
        float: $ldirection;
        margin-bottom: 1em;
        clear: none;
        padding-#{$rdirection}: 1em;
        min-height: 200px;
      }
      .desc {
        width: 60%;
        padding-#{$ldirection}: 2%;
        float: $ldirection;
        .product_name {
          width: 80%;
          font-weight: bold;
          line-height: 1;
          text-transform: uppercase;
          padding-#{$rdirection}: 18px;
          margin-top: 1em;
        }
      }
      .price {
        width: 100%;
        clear: both;
        float: $ldirection;
        display: none;
      }
      .bogo-offer {
        .qty {
          float: $ldirection;
        }
        .price {
          display: block;
          position: absolute;
          #{$ldirection}: 85px;
          top: 75px;
        }
        .total {
          position: absolute;
          top: 140px;
        }
      }
      .qty {
        padding-#{$ldirection}: 2%;
        display: block;
        float: $rdirection;
        margin-top: 15%;
      }
      .total {
        width: 30%;
        display: inline-block;
        position: absolute;
        #{$ldirection}: 24%;
        top: 75px;
      }
      .replenishment {
        &.cart-item__replenishment {
          clear: both;
          margin-top: 50px;
          .replenishment__description {
            a {
              color: $gray;
              display: inline-block;
              margin-bottom: 5px;
            }
          }
          .replen_notice {
            font-size: 12px;
            line-height: 15px;
            margin-top: 5px;
          }
        }
      }
      .cart-item__remove-form {
        .remove_link {
          position: absolute;
          top: 12px;
          #{$rdirection}: 0;
          text-decoration: none;
          border: 0;
          font-size: 16px;
        }
        .cart-item__add-to-favorites {
          display: none;
        }
      }
      .sample {
        .product_name {
          width: 100%;
        }
        .remove-sample {
          margin-bottom: 2px;
        }
        .qty {
          position: relative;
          top: 0;
          float: $ldirection;
          margin: 22px 0;
        }
        .cart-item__price--mobile-only {
          display: block;
        }
        .total {
          width: 77%;
          position: relative;
          #{$ldirection}: 7px;
          top: 0;
        }
      }
      .product__thumb {
        min-height: 130px;
      }
    }
  }
}

.viewcart {
  section.viewcart {
    #promo-bca-panel {
      padding: 20px 16px;
      .promo-bca-content__description {
        font-size: 16px;
      }
      .donation-amounts__label {
        padding: 0 4px;
      }
    }
  }
}

.links-panel {
  .links-panel__title.checkout__panel-title {
    font-size: 28px;
    line-height: 30px;
    margin: 10px 0;
    letter-spacing: -1px;
    font-weight: normal;
    display: inline-block;
    padding: 0;
    text-transform: uppercase;
  }
  ul.links_list {
    li.link {
      font-size: 14px;
      margin: 0 0 5px 0;
      letter-spacing: 0.08em;
      a {
        text-decoration: underline;
        text-transform: uppercase;
      }
    }
  }
}

.cart-item__monogram {
  display: inline-block;
  &-text {
    img {
      width: 30px;
    }
  }
  &-label {
    padding-top: 6%;
  }
}

body#viewcart {
  .product-list {
    .cart_item__error--hazmat {
      display: none;
    }
  }
  .order-summary__shipping-content {
    a.ship-method__information {
      margin: 10px 0;
      display: inline-block;
    }
  }
  .checkout {
    &.viewcart {
      padding: 1em;
      margin-top: 60px;
    }
  }
  .sticky-checkout-button {
    display: block;
    background-color: $white;
    padding: 10px;
    position: fixed !important;
    bottom: 0;
    #{$rdirection}: 0;
    z-index: 99;
    visibility: visible;
    width: 100%;
    margin-bottom: 0;
    text-transform: uppercase;
    text-align: center;
    box-shadow: 0 -3px 13px 3px $light-gray;
    .js-checkout-buttons-unhide,
    .js-shopping-buttons-unhide {
      background: $color-off-black;
      color: $white;
      width: 100%;
      font-size: 16px;
      float: $ldirection;
      padding: 15px 19px 10px;
      text-align: center;
    }
    .mobile-hidden {
      display: none;
    }
    .btn {
      &.disabled-button {
        background: $color-light-gray;
        pointer-events: none;
      }
    }
  }
  #bottom-viewcart-buttons {
    display: none;
  }
  #sticky-checkout-button-overlay {
    .viewcart-buttons-panel {
      margin: 0px;
      padding: 0px;
    }
    .paypal-checkout {
      display: block;
      margin: 5px;
      margin-bottom: 20px;
      img {
        vertical-align: top;
        padding-top: 6px;
        margin-#{$ldirection}: 5px;
      }
    }
    a {
      &.paypal-checkout {
        border: 1px solid $black;
        height: 50px;
      }
    }
    .continue-shopping {
      display: none;
    }
    a.btn-primary {
      background: $color-off-black;
      color: $white;
      font-size: 16px;
      padding: 15px 19px 10px;
      display: block;
      margin: 5px;
    }
  }
  .checkout-header {
    border-bottom: 1px solid $light-gray;
    float: $ldirection;
    width: 100%;
    font-weight: bold;
    &__title {
      font-size: 36px;
      text-align: center;
    }
    a {
      &.continue-shopping {
        display: none;
      }
    }
    #top-viewcart-buttons {
      display: none;
    }
  }
  #recommended-products-panel {
    display: none;
    .recommended-product-items {
      .recommended-item {
        width: 100%;
        float: none;
        margin: 0 auto;
        text-align: center;
        a {
          float: none;
          img {
            width: 40%;
          }
        }
        .description {
          float: none;
          margin: 0 auto;
          width: auto;
        }
      }
    }
  }
  .opc__footer {
    padding: 20px 11px;
    text-align: center;
  }
}

#delivery-options-panel {
  header {
    margin: 0.5em 0;
    font-weight: bold;
    h2 {
      margin: 0 0 0.5em 0;
    }
  }
  .delivery-date-wrapper {
    display: block;
    margin-bottom: 20px;
  }
}

.checkout__sidebar {
  margin: 0;
  border-top: none;
  #need-help-panel {
    display: none;
  }
  #delivery-options-panel {
    border: none;
  }
  .tab-switcher {
    display: none;
  }
  #offer-code-panel {
    border: none;
    header {
      margin: 0.5em 0;
    }
    label.offer-code__byline {
      display: none;
    }
    input {
      width: 100%;
      margin-top: 10px;
      &.form-submit {
        width: 28%;
      }
    }
  }
  #links-panel {
    border-top: 1px solid $light-gray;
    border-bottom: none;
    margin: 0;
    padding: 30px 11px;
    header {
      margin: 0.5em 0;
    }
    .content {
      .links_list {
        li {
          margin-bottom: 5px;
          a {
            text-decoration: underline;
            border: 0;
          }
        }
      }
    }
  }
}

section.viewcart-buttons-panel {
  border: none;
  margin: 30px 0;
  .go-shopping {
    display: none;
    &:hover {
      background: $color-off-black;
      color: $white;
      text-decoration: none;
    }
  }
  .continue-shopping {
    display: block;
  }
  .continue-checkout-btns {
    float: $rdirection;
    width: 70%;
    .continue-checkout {
      float: $rdirection;
      padding: 9px 19px 8px 19px;
      min-width: 116px;
      max-width: 100%;
      background: $color-off-black;
      color: $white;
    }
  }
}

#add-samples-message {
  display: none;
}

#order-summary-panel {
  header {
    margin: 0.5em 0;
    font-weight: bold;
    h2 {
      margin: 0 0 0.5em 0;
    }
  }
  .order-summary__content {
    .label {
      float: $ldirection;
      margin-bottom: 1.5em;
      width: 75%;
    }
    .value {
      float: $rdirection;
      width: 25%;
    }
    .total {
      border-top: 1px solid $light-gray;
      font-weight: bold;
      margin-bottom: 0;
      padding: 1em 0;
    }
  }
}

#samples {
  #samples-panel {
    padding: 0 20px;
    .samples-buttons.top {
      display: none;
      .return-link {
        float: $ldirection;
        margin-top: 10px;
      }
      .add-button {
        float: $rdirection;
      }
    }
    .product-img {
      width: 28%;
      float: $ldirection;
      img {
        max-width: 94px;
      }
    }
    .product__desc {
      width: 50%;
      float: $ldirection;
    }
    .product-subhead {
      width: 50%;
      float: $rdirection;
      margin-top: 20px;
    }
    .product-size {
      width: 50%;
      float: $rdirection;
      margin-top: 10px;
    }
    .sample-select-button {
      clear: both;
      width: 100%;
      padding: 9px 19px 8px 19px;
      background: $white;
      color: $color-off-black;
      display: block;
      text-align: center;
      text-decoration: none;
      margin: 1em 0;
      border: 1px solid $color-off-black;
      &.selected {
        background: $color-off-black;
        color: $white;
      }
    }
    .view-larger {
      display: none;
    }
  }
  .viewcart-buttons-panel {
    display: none;
    float: $ldirection;
    width: 100%;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    margin: 30px 0;
    padding: 10px;
    .edit-cart {
      float: $ldirection;
      margin-top: -80px;
      a {
        text-decoration: underline;
      }
    }
    .continue-buttons {
      background-color: $white;
      padding: 10px;
      position: fixed !important;
      bottom: 0;
      #{$rdirection}: 0;
      z-index: 99;
      visibility: visible;
      width: 100%;
      margin-bottom: 0;
      text-transform: uppercase;
      text-align: center;
      box-shadow: 0 -3px 13px 3px $light-gray;
      .continue-checkout {
        padding: 9px 19px 8px 19px;
        background: $color-off-black;
        color: $white;
        text-align: center;
        margin-#{$rdirection}: 10px;
        display: block;
      }
    }
  }
  .samples-buttons {
    margin-top: 20px;
    float: $rdirection;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    padding: 10px;
    .continue-button-wrapper {
      background-color: $white;
      padding: 10px;
      position: fixed;
      bottom: 0;
      #{$rdirection}: 0;
      z-index: 99;
      visibility: visible;
      width: 100%;
      margin-bottom: 0;
      text-transform: uppercase;
      text-align: center;
      box-shadow: 0 -3px 13px 3px $light-gray;
      .btn-primary {
        width: 100%;
      }
    }
  }
  section {
    .panel {
      border-bottom: 1px solid $white;
    }
  }
  .opc__footer {
    text-align: center;
    margin-top: 20px;
  }
}

#index {
  header {
    h2 {
      text-transform: uppercase;
    }
  }
  #order-summary-panel {
    display: none;
    .order-summary__shipping-label {
      margin-bottom: 0;
    }
  }
  .order-summary__shipping-content {
    select {
      background-color: $white;
    }
    a.ship-method__information {
      margin: 5px 0;
      border-bottom: 0;
    }
  }
  .qas_container {
    input[name='qas_search'] + label {
      overflow: hidden;
    }
    input[name='qas_search'].adpl__mode-label + label:before {
      content: '';
    }
    input[name='qas_search'].adpl__mode-label + label {
      visibility: hidden;
    }
  }
  .giftwrap {
    input[type='text'].js-placeholder-mode + label {
      opacity: 1 !important;
    }
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    input::-webkit-input-placeholder {
      color: transparent;
    }
    input:-moz-placeholder {
      /* Firefox 18- */
      color: transparent;
    }
    input::-moz-placeholder {
      /* Firefox 19+ */
      color: transparent;
    }
    input:-ms-input-placeholder {
      color: transparent;
    }
  }
  .sms-mobile {
    input[type='tel'].js-placeholder-mode + label {
      opacity: 1 !important;
    }
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    input::-webkit-input-placeholder {
      color: transparent;
    }
    input:-moz-placeholder {
      /* Firefox 18- */
      color: transparent;
    }
    input::-moz-placeholder {
      /* Firefox 19+ */
      color: transparent;
    }
    input:-ms-input-placeholder {
      color: transparent;
    }
  }
  .checkout-single-page {
    .checkout {
      a.js-afterpay-learnmore {
        display: none;
      }
    }
  }
  &.checkout {
    position: static;
  }
  .checkout__index-content {
    padding: 1em;
    margin-top: 0px;
  }
  .continue-button-wrapper {
    position: fixed;
    bottom: 0;
    #{$ldirection}: 0;
    width: 100%;
    margin: 0;
    padding: 0 15px 15px 15px;
    background: $white;
    z-index: 100;
    .btn--fixed_width,
    .btn-primary {
      width: 100%;
    }
  }
  #sign-in-panel {
    #new-or-returning-radios {
      .new-customer {
        margin-bottom: 5px;
        display: inline-block;
      }
      .returning-customer {
        margin-bottom: 5px;
        display: inline-block;
      }
    }
    input {
      width: 100%;
    }
    input[type='radio'] {
      width: auto;
    }
  }
  #shipping-panel {
    .checkout__panel-title {
      display: inline-block;
      margin-bottom: 0px;
    }
    .edit-controls {
      h2.shipping-panel__title {
        a {
          border-bottom: none;
        }
      }
      h2.shipping-panel__title + a.edit {
        min-width: 20px;
        border: none;
        padding: 0;
        margin: 15px 0 0 0;
        font-size: 18px;
        color: $color-off-black;
        border-bottom: 1px solid $color-off-black;
        height: auto;
        line-height: normal;
        background: 0 0;
        float: $rdirection;
        font-weight: bold;
      }
    }
    #checkout_shipping_panel {
      input {
        width: 100%;
      }
      select {
        width: 100%;
        margin-bottom: 20px;
      }
      input[type='radio'] {
        width: auto;
        & ~ label {
          display: inline-block;
          width: 94%;
        }
      }
      input[type='checkbox'] {
        width: auto;
        & ~ label {
          width: 90%;
          clear: none;
          display: inline-block;
          margin-#{$ldirection}: 10px;
        }
      }
      .edit-shipping-radio {
        margin: 7px 0px;
      }
      .title-fields {
        .title.form-item {
          width: 100%;
        }
      }
      .form-item {
        &.first-name {
          width: 49%;
          float: $ldirection;
        }
        &.last-name {
          width: 49%;
          float: $rdirection;
        }
        &.city {
          width: 32%;
          float: $ldirection;
        }
        &.state {
          width: 34%;
          float: $ldirection;
          margin-#{$ldirection}: 5px;
        }
        &.postal-code {
          width: 31%;
          float: $rdirection;
        }
        input[type='tel'] + label:before,
        input[type='text'] + label:before,
        input[type='password'] + label:before,
        input[type='email'] + label:before {
          content: attr(data-required) attr(placeholder);
        }
      }
      .field-container--grid.phones {
        clear: both;
      }
      .giftwrap {
        .giftwrap__is-gift {
          width: 100%;
          img {
            display: block;
          }
        }
        img {
          display: none;
        }
      }
    }
    &.adpl {
      input[name='qas_search'] {
        height: 1em;
        padding: 20px 10px;
      }
    }
    textarea {
      @include setdefault_height;
      height: 9.5em;
      font-size: 1em;
      -webkit-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      -webkit-box-shadow: none;
      box-shadow: none;
      resize: none;
      outline: 0;
      border-color: $color-light-gray;
      width: 100%;
      & + label {
        @include setdefault_height;
        @include setdefault_inputpadding;
        @include setdefault_inputmargin;
        display: block;
        font-size: 1em;
        pointer-events: none;
        height: calc(9.5em - -2px);
        line-height: 1;
        padding-top: calc(1em - -1px);
        margin-top: calc(-9.5em - 2px);
        cursor: text;
        &:before {
          @include setdefault_beforemargin;
          content: attr(data-required) attr(placeholder);
          display: inline-block;
          color: $color-light-grey-text;
          margin: 0 calc(1em - -2px);
          white-space: nowrap;
          -webkit-transition-property: -webkit-transform, color;
          transition-property: transform, color;
          -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
          -webkit-transition-delay: 0;
          transition-delay: 0;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
          -webkit-transform-origin: left center;
          -ms-transform-origin: left center;
          transform-origin: left center;
          text-transform: uppercase;
        }
        span.label-content {
          display: none;
        }
      }
      &.active + label:before,
      &.js-label-mode + label:before,
      &:focus + label:before {
        background: $white;
        line-height: 1;
        padding: 0 1.5px;
        -webkit-transform: translateY(-1.5em) translateY(-0.5px) scale(0.8, 0.8);
        transform: translateY(-1.5em) translateY(-0.5px) scale(0.8, 0.8);
      }
      &.js-label-mode + label:before {
        content: attr(data-required) attr(alt);
      }
    }
    .gift-options {
      input[type='text'],
      textarea {
        &[data-error] {
          border-color: $color-red;
        }
      }
    }
  }
  #viewcart-panel {
    display: block;
    visibility: visible;
  }
  #offer-code-panel {
    border-bottom: 1px solid $light-gray;
  }
  #registration-panel {
    &.active {
      input[type='text'],
      input[type='password'] {
        width: 100%;
      }
      .checkout-password-label {
        span.label-content {
          display: none;
        }
      }
    }
    &.finished {
      .registration-panel__title {
        display: inline-block;
        #registration-panel-edit {
          float: $ldirection;
          margin: 0;
        }
      }
      a {
        &.edit {
          float: $rdirection;
          margin: 15px 0px;
        }
      }
    }
  }
  .panel.collapsed,
  #promo-bca-panel,
  .right.checkout__sidebar {
    display: none;
  }
  .checkout__sidebar {
    &.display-footer {
      display: block;
    }
    #shopping-bag-panel,
    #viewcart-panel {
      display: none;
    }
  }
  .checkout__subtitle {
    text-transform: uppercase;
    font-weight: bold;
  }
  .adpl {
    input[type='email'],
    input[type='tel'],
    input[type='password'],
    input[type='text'],
    select {
      &.js-label-mode {
        & + label:before {
          background: $white;
          line-height: 1;
          padding: 0 1.5px;
          -webkit-transform: translateY(-1.5em) translateY(-0.5px) scale(0.8, 0.8);
          transform: translateY(-1.5em) translateY(-0.5px) scale(0.8, 0.8);
        }
      }
    }
    .qas {
      input[type='text'] {
        & + label:before {
          white-space: inherit;
        }
      }
    }
  }
  &.active-panel-registration,
  &.active-panel-shipping {
    .checkout__sidebar {
      &.right {
        display: block;
        #links-panel,
        #need-help-panel,
        #order-summary-panel {
          display: none;
        }
        &.display-footer {
          #links-panel {
            display: block;
          }
        }
        .shopping-bag-panel {
          header,
          .item-count {
            float: $ldirection;
            width: 50%;
          }
          .item-count {
            text-align: $rdirection;
            margin: 15px 0;
          }
        }
      }
    }
  }
  .opc__footer {
    text-align: center;
  }
  .password-description {
    display: none;
  }
  #payment-panel {
    .esalon__content {
      select {
        width: 100%;
      }
    }
  }
}

#confirmation-page {
  margin: 10px;
  .checkout-registration__show-password {
    display: none;
  }
  #confirmation-panel {
    margin: 0;
    header {
      padding: 25px 0;
      width: 100%;
      text-align: center;
      .checkout-header__title {
        font-size: 19px;
      }
    }
    .content {
      border-top: 1px solid $light-gray;
      padding-top: 20px;
      margin: 10px;
    }
    .confirmation-panel__actions {
      margin: 10px;
      .print-buttons {
        .receipt-print-btn {
          background-color: $color-off-black;
          color: $white;
          width: auto;
          padding: 9px 19px 8px 19px;
        }
      }
      .return-link-container {
        .button {
          background-color: $color-off-black !important;
          border: none;
          border-radius: initial;
          padding: 9px 19px 8px 19px !important;
          line-height: 19px !important;
          height: auto;
        }
      }
    }
  }
  #completed_checkout_registration {
    .form-item {
      height: auto;
    }
    .checkout-password-label {
      display: none;
    }
  }
  .form-item {
    &.password-description {
      display: none;
    }
  }
}

.viewcart,
.index,
.checkout,
#samples {
  .site-footer__sticky--offer {
    display: none;
  }
}

.accordionPanel {
  header {
    cursor: pointer;
  }
  i {
    float: $rdirection;
    font-family: 'icons';
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:before {
      content: map-get($icons, caret--down);
    }
  }
  .active i {
    &:before {
      content: map-get($icons, caret--up);
    }
  }
}

#samples-pane,
#index,
#confirmation-page {
  .loading {
    position: fixed;
    top: 0;
    #{$ldirection}: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: $white;
    text-align: center;
    opacity: 0;
    filter: alpha(opacity=00);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=00)';
    -webkit-transition: opacity 3s ease-in-out;
    -moz-transition: opacity 3s ease-in-out;
    -ms-transition: opacity 3s ease-in-out;
    -o-transition: opacity 3s ease-in-out;
    transition: opacity 3s ease-in-out;
    &.fade-in {
      opacity: 0.8;
      filter: alpha(opacity=80);
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
    }
    &:before {
      display: inline-block;
      margin-#{$rdirection}: -0.25em;
      height: 100%;
      vertical-align: middle;
    }
    .text {
      display: inline-block;
      padding-top: 50px;
      width: 100px;
      vertical-align: middle;
    }
  }
}

body {
  &#viewcart,
  &#samples,
  &#index {
    .site-header__utility-right {
      display: none;
    }
    .site-header__utility-left {
      .site-header__utility-item {
        display: none;
      }
      .site-header__utility-logo {
        display: block;
      }
    }
    .mobile-checkout-back {
      display: block;
      background: url(/media/images/checkout/back-button.png) no-repeat 6px center;
      width: 45px;
      height: 40px;
      float: $ldirection;
      margin-#{$ldirection}: 2%;
    }
    // BOGO OfferCode Styles
    .bogo-offer {
      .cart-item {
        &__replenishment,
        &__remove-form,
        &__add-to-favorites {
          display: none;
        }
      }
    }
  }
}

body#confirm {
  .footer {
    display: none;
  }
  .site-header__utility-right,
  .site-header__utility-left .site-header__utility-item {
    display: none;
  }
  .site-header__utility-left .site-header__utility-logo {
    display: block;
  }
  .mobile-checkout-back {
    display: block;
    width: 45px;
    height: 40px;
    float: $ldirection;
    margin-#{$ldirection}: 2%;
  }
  .opc__footer {
    text-align: center;
  }
}

.checkout {
  .panel:not(.viewcart-panel) {
    #error_offer_bogo {
      display: none;
    }
  }
}
.engraving {
  &_option {
    &.engraving_restrict {
      background-color: $color-wh;
    }
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    background-color: $color-light-br;
    clear: $ldirection;
    .engraving-cart-control {
      margin: 2em;
      .engraving_option_text {
        font-size: 12px;
        width: auto;
        margin: 0;
        .engraving_title {
          font-size: 16px;
          display: block;
          font-weight: bold;
          letter-spacing: 0;
        }
        .engrave_price {
          color: $color-blue;
          font-size: 12px;
          text-align: $ldirection;
        }
      }
      .engraving-show-toggle,
      .engraving-toggle-cancel {
        background-color: $color-dark-yellow;
        color: $color-light-br;
        padding: 0 6px;
        font-size: 11px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-weight: 700;
        border: solid 2px $color-dark-yellow;
        letter-spacing: 0;
        width: 100%;
        margin-top: 20px;
      }
      .engraving-toggle-cancel {
        background-color: $color-light-br;
        color: $color-dark-yellow;
        margin: 0;
      }
    }
  }
}
.engraving-wrapper {
  background-color: $color-light-br;
  width: 100%;
  overflow: hidden;
  .background-color {
    .engraving-form-element-content {
      .monogram {
        &_custom_text {
          margin-top: 20px;
          input {
            width: 100%;
            .engraving-message {
              width: 100%;
            }
          }
          label {
            margin-bottom: 5px;
          }
          .engraving-message-count {
            margin-#{$ldirection}: inherit;
            text-align: $rdirection;
            display: block;
            padding-#{$rdirection}: 1em;
            clear: both;
            margin-bottom: 10px;
            span {
              font-size: 13px;
              color: $color-dark-red;
            }
          }
        }
        &_custom_image_right {
          width: 100%;
          margin: 10px auto;
          input {
            width: 100%;
          }
        }
      }
    }
    .action-buttons-div {
      width: 100%;
      margin: 10px 0 20px 0;
      clear: both;
      .engraving-save,
      .engraving-cancel {
        display: block;
        float: none;
        font-weight: 700;
        padding: 12px;
        margin: 10px auto;
        text-decoration: none;
        text-align: center;
        width: 50%;
        border: 1px solid $color-red-orange;
        color: $color-wh;
        background: $color-red-orange;
        a {
          color: $color-wh;
          padding: 0;
          margin: 0;
          margin-#{$ldirection}: 40px;
        }
      }
    }
  }
  .monogram-error {
    margin-bottom: 10px;
    color: $red;
  }
  .engraving {
    &-service-text {
      margin: 0;
      padding: 1em;
      background-color: $color-red-orange;
      color: $color-wh;
    }
    &-view {
      padding-top: 1em;
      &-title {
        margin: 10px;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0;
        &-top {
          font-weight: 700;
          font-size: 16px;
          text-align: $ldirection;
          letter-spacing: 0;
        }
      }
      &-message {
        text-align: center;
        cursor: pointer;
        font-size: 26px;
        height: 71px;
        background: $color-wh;
        clear: both;
        width: 100%;
        float: $ldirection;
      }
      &-message-content {
        overflow: auto;
        overflow-y: hidden;
        border: 2px solid $color-hexgrey;
        .engraving-view-messages {
          white-space: nowrap;
          word-wrap: normal;
          font-size: 18px;
          line-height: 65px;
          vertical-align: middle;
          .monogram_custom_text {
            font-size: 20px;
            padding: 0 15% 0 0;
            display: inline-block;
            white-space: pre;
            font-family: Calibri;
          }
          img {
            width: 45px;
            height: auto;
            vertical-align: middle;
          }
        }
      }
      .engraving-view {
        &-control {
          text-align: center;
          font-weight: 700;
          width: 100%;
          margin-top: 13px;
          float: $ldirection;
          .engraving-toggle-edit {
            margin-#{$ldirection}: 15px;
            display: inline-block;
            margin-top: 10px;
          }
        }
        &-footer {
          float: $ldirection;
          margin: 3% 0 3% 5%;
          .note-wrap {
            margin: 1vh auto;
            text-align: $ldirection;
            .note-text {
              margin-bottom: 0;
              margin-#{$ldirection}: 1.2em;
              padding: 0.5em;
              text-indent: -1.2em;
              font-size: 0.7rem;
              line-height: 1.25;
            }
          }
        }
      }
    }
  }
}
#order_detail {
  .sample {
    &.engraving_service_sku {
      display: none;
    }
  }
}
