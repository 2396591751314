.site-header__wrapper {
  body.toolbar & {
    @include breakpoint($portrait-up) {
      top: 0px;
    }
  }
}

#index {
  .checkout {
    #checkout_shipping_panel {
      .title-fields {
        #form--address_shipping--field--SHIPFORM_TITLE {
          @include breakpoint($medium-up) {
            width: 50%;
          }
          width: 100%;
          margin-bottom: 1.5em;
        }
        .form-item {
          display: inline-block;
          width: auto;
        }
      }
      .form-item {
        &.country-id {
          width: 100%;
          .country_select {
            width: 50%;
            @include breakpoint($small-down) {
              width: 100%;
            }
          }
        }
        &.default-shipping {
          width: 100%;
        }
        @include breakpoint($small-down) {
          width: 100%;
          float: none;
          padding-right: 0px;
        }
        width: 50%;
        float: left;
        padding-right: 1.33em;
      }
      .giftwrap {
        label {
          width: 90%;
        }
      }
      .continue-button-wrapper {
        float: left;
      }
    }
  }
}

#address_book {
  .ui-widget-content {
    width: 100% !important;
    .ui-state-hover {
      background: #dadada !important;
      border: none !important;
      color: #212121;
    }
  }
}

.spp__container {
  .product-full__details {
    .product-points {
      display: none;
    }
  }
  .instore-inventory-container {
    .product-store-check {
      &__inline--container {
        border-top: 0;
        padding-bottom: 10px;
        border-bottom: 1px solid $black;
      }
      .location-controls {
        &__zip {
          margin-top: 10px;
        }
        &__hdr {
          margin: 0;
        }
      }
    }
    .find-in-store {
      border: 1px solid $color-off-black;
      width: 100%;
      padding: 5px 0;
      display: inline-block;
      text-align: center;
      font-size: 16px;
      margin: 10px 0;
      cursor: pointer;
    }
  }
}

.ajax-wait-inventory {
  .instore-inventory-container {
    opacity: 0.5;
  }
}

.trustmark_logo {
  width: 100%;
  padding: 40px 15px;
  .trustmark_img {
    width: auto;
    text-align: center;
    float: none;
    margin-bottom: 20px;
    img {
      width: 44%;
    }
  }
}

.store-locator__results-container {
  .store-locator__tooltip-product-types {
    text-transform: uppercase;
    font-size: 12px;
    .pc {
      padding: 2px 2px 0px 2px;
      &.pc {
        &--tf {
          background-color: #632522;
          padding: 2px 4px 0px 4px;
        }
      }
    }
  }
}

.registration-content {
  .accepted-privacy-policy {
    label {
      &.error {
        span.label-content,
        a.terms_privacy {
          color: $red;
        }
      }
    }
    .label {
      &.error {
        .terms_privacy {
          border-bottom: 1px solid $red;
        }
      }
    }
  }
}

#order_detail {
  .order-details-page {
    .order-products {
      .cart-item {
        &__status-label {
          display: none;
        }
      }
    }
  }
}

.welcome-15 {
  &.success {
    .site-email-signup {
      &__success-reminder {
        font-size: 13px;
      }
    }
  }
}

.site-footer {
  &__sticky {
    #AV_AU_LiveChat {
      a {
        background: $color-off-black;
        text-decoration: underline;
      }
    }
    &--offer {
      left: 216px;
    }
  }
}

.lp_mobile {
  #lpChat {
    &.lp_main_ltr * {
      min-width: 40px !important;
    }
  }
}

.spp-content {
  .product-full {
    .collapsible-block__title {
      .icon {
        &:after {
          margin-#{$rdirection}: 10px;
        }
      }
    }
    .sku-menu__container,
    .product__inventory-status {
      text-align: #{$rdirection};
    }
    .product__inventory-status {
      margin-top: 10px;
    }
  }
}

.lpdv {
  .lp-window-root {
    .lp_survey_area {
      .lp_pages_area {
        span {
          &.lp_radio_button {
            display: flex;
          }
        }
      }
    }
  }
}

.adv_cal_cap {
  .adv-cal-cap-container {
    .adv-cal-cap-popup {
      .popup-content-wrapper {
        .offer-details {
          &__img,
          &__content,
          &__controls {
            float: unset;
            text-align: center;
          }
        }
      }
    }
  }
}
