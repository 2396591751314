///
/// @file global/_variables.scss
///

$color-light-br: #f4f2ee;
$color-dark-yellow: #120e02;
$color-red-orange: #392720;
$color-wh: #ffffff;
$color-blue: #03092a;
$color-dark-red: #ff0000;
$color-dulux: #cdcbc9;
$color-lightgrey: #a9a9a9;
$color-hexgrey: #cccccc;
