.click-and-collect-method-select {
  float: left;
  @include breakpoint($small-down) {
    padding: 0;
    .auspost-overlay.button {
      margin: 20px;
    }
  }
  .radio-button {
    float: left;
    width: 67%;
    @include breakpoint($small-down) {
      #select-click-and-collect-radio {
        float: left;
        margin-top: 5px;
      }
      width: 100%;
    }
  }
  .auspost-logo {
    float: right;
    width: 33%;
    @include breakpoint($small-down) {
      display: none;
    }
  }
  label {
    text-transform: none;
    @include breakpoint($small-down) {
      max-width: 90%;
      margin-left: 10px;
    }
  }
  .auspost-label {
    font-weight: bold;
    display: inline-block;
    @include breakpoint($small-down) {
      max-width: 90%;
      margin-left: 0;
    }
  }
  .auspost-note {
    display: inline-block;
    margin-left: 22px;
    margin-top: 10px;
  }
  .auspost-address-display {
    p {
      margin: 0;
    }
    margin-bottom: 10px;
  }
}

#colorbox.auspost-overlay {
  .auspost-continue {
    width: 100%;
    .location-selected {
      margin: 20px auto;
      display: block;
      width: 150px;
    }
  }
  @include breakpoint($large-up) {
    #cboxClose {
      top: 2px;
      right: 9px;
      &:before {
        width: 22px;
        height: 22px;
      }
    }
  }
}

#shipping-panel {
  #checkout_shipping_panel {
    .content.click-and-collect-method-select {
      padding: 0;
    }
  }
}

@include breakpoint($small-down) {
  .content.click-and-collect-method-select {
    margin: 10px 0;
  }
}

div#locationFinderPopoverWrapper button {
  padding: 0;
  min-width: auto;
}
