/* Common styles for all social login sections */
.social-login {
  &__terms,
  &__opt-in-label {
    text-transform: uppercase;
    font-weight: 100;
  }
}
/* Account signin page styling */
#signin {
  .social-login {
    &__opt-in-checkbox {
      width: 23px;
      padding: 6px;
    }
    @include breakpoint($medium-up) {
      max-width: 485px;
      margin: 0 auto;
      &__opt-in-label {
        margin-left: 5px;
        display: inline;
      }
      &__divider {
        width: 100%;
      }
      &__email-opt-in,
      &__container {
        padding-left: 0;
        display: inline-block;
      }
      &__terms {
        padding: 0;
      }
      &__opt-in-checkbox {
        width: auto;
      }
    }
  }
}
/* Checkout signin page styling */
.checkout__content {
  .social-login {
    &__divider {
      width: 100%;
    }
    &__terms {
      padding: 0;
    }
    &__container {
      @include breakpoint($medium-up) {
        padding: 0 10px 20px 10px;
      }
    }
  }
}

body#index {
  #sign-in-panel {
    .social-login {
      &__opt-in-checkbox {
        width: 23px;
        @include breakpoint($medium-up) {
          width: auto;
        }
      }
    }
  }
  .column.left.checkout__content {
    .social-login {
      input[type='checkbox'] ~ label {
        width: auto;
        margin-left: 7px;
        display: inline;
      }
      &__email-opt-in {
        text-align: left;
        display: block;
      }
    }
  }
}
/* Order confirmation page styling */
.confirmation-panel {
  .social-login {
    display: inline-block;
    width: 99%;
    border-top: 1px solid $color-light-gray;
    @include breakpoint($medium-up) {
      width: 93%;
    }
    &__terms,
    &__divider {
      display: none;
    }
    &__container {
      text-align: left;
      margin: 7px;
      @include breakpoint($medium-up) {
        margin: 0;
      }
    }
    &__info {
      display: block;
      @include breakpoint($medium-up) {
        padding-top: 10px;
      }
    }
    &__opt-in-label {
      text-transform: initial;
      letter-spacing: 0;
      font-weight: 400;
    }
    &__title {
      display: block;
      font-size: 1.17em;
      @include breakpoint($medium-up) {
        font-size: 38px;
        text-transform: uppercase;
      }
    }
  }
}

#confirm {
  @include breakpoint($landscape-down) {
    .site-footer {
      top: 109px;
      position: relative;
    }
  }
  .social-login {
    input[type='checkbox'] {
      width: 23px;
      padding: 6px;
      @include breakpoint($medium-up) {
        width: auto;
      }
    }
  }
}
/* Account profile page styling */
.social-info {
  &__content p a {
    text-decoration: underline;
  }
  &__connect {
    display: inline-flex;
  }
}
/* Gnav signin overlay styling */
.social-login.gnav {
  .social-login__email-opt-in label,
  .social-login__terms {
    text-align: left;
    line-height: 18px;
  }
}
