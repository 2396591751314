#payment-panel {
  .payment-container,
  .payment_container {
    span.payment-option {
      margin-bottom: 0px;
      font-size: 13px;
      text-transform: uppercase;
      margin-top: 20px;
      display: inline-block;
      &.form-item {
        &.radio {
          clear: left;
          width: 100%;
          @include breakpoint($medium-only) {
            width: 60%;
          }
        }
      }
      input[type='radio'] {
        margin: 2px 0;
        width: auto;
        & ~ label {
          clear: none;
          margin-left: 10px;
          width: auto;
        }
      }
    }
    input[type='radio'] {
      margin: 2px 0;
      & ~ label {
        margin-left: 10px;
      }
    }
    #adyen-payment {
      margin-top: 3%;
      @include breakpoint($medium-only) {
        width: 100%;
      }
      input {
        &.chckt-checkbox {
          margin: 4px 10px 4px 0;
        }
      }
      .chckt-button {
        font-family: $primary-font;
        &:not(.chckt-button--disabled) {
          background: $color-off-black;
          border: 1px solid $color-off-black;
          @include breakpoint($medium-only) {
            &:hover {
              background: $white;
            }
          }
        }
      }
      .chckt-pm {
        &__pending-message {
          background-color: $color-off-black;
        }
        &__details {
          padding: 0 50px 20px 50px;
          .chckt-form-label {
            &--exp-date {
              width: 55%;
            }
            &--cvc {
              width: 40%;
            }
          }
          .chckt-input-field {
            &--cvc {
              max-width: 100%;
            }
          }
        }
      }
      .chckt-more-pm-button {
        display: none;
        &--shown {
          display: block;
        }
      }
      .chckt-button-container {
        .chckt-more-pm-button {
          margin: 0;
        }
      }
    }
  }
  .gift-card-entry-container {
    .form-item {
      &.gift-card-number,
      &.gift-card-pin {
        margin: 0 0 20px 0;
        @include breakpoint($small-down) {
          width: 100%;
        }
        width: 48%;
        display: inline-block;
      }
    }
  }
  .field-container {
    clear: both;
    margin: 10px 0;
    a {
      border-bottom: 0;
    }
  }
  .payment-type {
    display: inline-block;
    .related-media {
      &.cc:first-child {
        margin-left: 5px;
        float: none;
      }
      &.cc {
        margin-left: 5px;
        float: right;
      }
      &.pp {
        margin-left: 10px;
      }
    }
  }
  .payment-form {
    .card-number {
      width: 100%;
      margin-right: 15px;
      float: left;
      input {
        width: 100%;
      }
    }
    .cvv {
      width: 33%;
      float: left;
      input {
        width: 100%;
      }
      a {
        display: none;
      }
    }
    .expires-date__month.select,
    .expires-date__year.select {
      width: 33.3%;
      float: left;
      select {
        width: 100%;
        &.expiration_month:focus,
        &.expiration_year:focus {
          border: 1px solid $red;
        }
      }
    }
    .expires-year {
      margin-left: 11px;
    }
    span,
    input {
      float: left;
    }
    .card-type {
      display: none;
    }
  }
  #security-information,
  #payment-information,
  #security-code {
    display: none;
  }
  header {
    padding: 0;
    .payment-panel__title {
      padding: 0 10px;
      margin-top: 20px;
    }
    .nz_address_message {
      color: $color-lighter-brown;
      background: $white;
      padding: 15px 0 0 5px;
    }
  }
  div.messages {
    padding: 0 11px;
  }
}
