.change-password {
  &.confirm {
    .password-field {
      &__info {
        @include breakpoint($large-up) {
          #{$ldirection}: 22%;
          width: 25%;
        }
      }
    }
  }
  .password-field {
    &__info {
      z-index: 99;
      &-reset {
        position: relative;
        padding-#{$rdirection}: 13px;
        margin-#{$rdirection}: -125px;
        @include breakpoint($landscape-up) {
          position: absolute;
        }
      }
    }
  }
}

.profile__reset-password {
  .profile-info {
    &__item {
      input {
        padding: 20px;
      }
    }
  }
}
