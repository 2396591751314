.checkout {
  .single-message {
    color: $red;
    &.MESSAGE {
      word-wrap: break-word;
    }
  }
}

.optanon-show-settings-popup-wrapper {
  .optanon-show-settings-button {
    &.optanon-toggle-display {
      .optanon-show-settings-middle {
        .optanon-show-settings {
          color: $color-darkest-green !important;
          &:hover {
            color: $color-darkest-green !important;
          }
        }
      }
    }
  }
}

.site {
  &-header {
    &__nav {
      display: flex;
      flex-direction: column;
    }
    &__content {
      .signin-block {
        &.signin-overlay {
          display: none;
        }
      }
      &.-block-sitewide-banner {
        .site-header:not(.content-block-sitewide-banner--enabled) & {
          order: 3;
        }
      }
    }
  }
}

#appointment-book-sections {
  .confirm-container {
    #book-appt-fieldset {
      .appt-book-mobile-prefix {
        width: 30%;
        float: left;
        border-right: none;
      }
      #registration-mobile-phone {
        width: 70%;
        float: left;
      }
      .registration-mobile-phone {
        .label {
          margin-left: 30%;
          float: left;
        }
      }
    }
    .book-appt-container {
      .registration__sms-list {
        display: none !important;
      }
    }
  }
}
