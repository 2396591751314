.account-section {
  select#form--profile_preferences--field--TITLE {
    margin-bottom: 15px;
  }
}

.address-book-page__overlay {
  .address-overlay {
    .country-id--top {
      margin-bottom: 7px !important;
      .country_select {
        width: 100%;
      }
    }
    .address {
      &.qas {
        padding-bottom: 25px;
      }
    }
    #qas_search_target {
      .city-state-zip {
        .postal-code {
          width: 100%;
        }
        .state {
          label {
            display: none;
          }
        }
      }
    }
    .form-item {
      &.required {
        padding-bottom: 13px;
      }
    }
  }
  #address_shipping {
    .first-name {
      width: 49%;
      float: left;
    }
    .last-name {
      width: 49%;
      float: right;
    }
    .city {
      width: 32%;
      float: left;
      display: inline;
    }
    .state {
      width: 34%;
      float: left;
      display: inline;
      margin-left: 5px;
      label {
        display: none;
      }
      select {
        width: 100%;
      }
    }
    .postal-code {
      width: 31%;
      float: right;
      display: inline;
    }
  }
  .address-form__default-shipping {
    clear: both;
  }
}

.competition_form_container {
  .error {
    color: $color-red;
  }
}

.add-payment-page {
  &__content {
    input[type='tel'] {
      width: 100%;
    }
  }
}

body {
  &#replenish {
    .account-section {
      padding: 50px 12px 20px 12px;
    }
  }
}
